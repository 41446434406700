import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css',
})
export class FooterComponent {
  constructor(private readonly router: Router) {}

  navegarPara(route: string): void {
    this.router.navigate([route]);
  }
}
