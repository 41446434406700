<footer class="footer-container">
  <div class="footer-top px-3 py-4">
    <div class="footer-top-content grid align-items-center">
      <div class="col-12 md:col-6 text-center md:text-left">
        <div class="txt-logo text-xl font-bold">
          Massagem<span class="bold">Aqui</span>
        </div>
      </div>
      <div class="col-12 md:col-6 text-center md:text-right">
        <div class="footer-copy text-sm">
          © 2025 Massagem Aqui. Todos os direitos reservados.
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="footer-bottom">
    <div class="grid footer-menu text-center md:text-left">
      <div class="col-12 md:col-4" (click)="navegarPara('login')">
        <div class="footer-item">
          <i class="pi pi-user"></i>
          <span>Cadastre Grátis</span>
        </div>
      </div>

      <div class="col-12 md:col-4">
        <div class="footer-item">
          <i class="pi pi-video"></i>
          <span>Vídeos</span>
        </div>
      </div>

      <div class="col-12 md:col-4">
        <div class="footer-item">
          <i class="pi pi-comment"></i>
          <span>Reviews</span>
        </div>
      </div>
    </div>
  </div> -->
</footer>
