import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@gm-services';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.css',
})
export class MenuComponent implements OnInit, AfterViewInit {
  items: MenuItem[] = [];
  itemsEnd: MenuItem[] = [];
  isMobile = false;
  menuVisivel = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.criaMenu();
    this.detectarMobile();
    window.addEventListener('resize', this.detectarMobile.bind(this));
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.detectarMobile.bind(this));
  }

  ngAfterViewInit(): void {
    this.detectarMobile();
    this.cdr.detectChanges();
  }

  detectarMobile(): void {
    this.isMobile = window.innerWidth <= 768;
  }

  criaMenu(): void {
    this.items = [
      {
        label: 'Página Inicial',
        icon: 'pi pi-home',
        routerLink: '/pagina-inicial',
      },
    ];
    this.itemsEnd = [
      { label: 'Cadastre-se', icon: 'pi pi-user', routerLink: '/login' },
    ];
    if (this.authService.isAuthenticated) {
      this.itemsEnd.push({
        label: 'Cadastrar Espaço',
        icon: 'pi pi-plus-circle',
        routerLink: '/cadastro-anuncio',
      });

      this.itemsEnd.push({
        label: 'Sair',
        icon: 'pi pi-arrow-right',
        command: () => this.sair(),
      });
    }
  }

  private sair(): void {
    console.log('SAIR');
    this.authService.logout();
    this.router.navigate(['login']);
  }

  public navegarPara(route: string): void {
    this.router.navigate([route]);
    this.menuVisivel = false;
  }

  public handleMenuItemClick(item: MenuItem, event: Event): void {
    if (item.command) {
      item.command({ originalEvent: event, item });
    } else if (item.routerLink) {
      this.navegarPara(item.routerLink);
    }
  }
}
