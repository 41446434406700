import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '@gm-guards';

const routes: Routes = [
  {
    path: 'pagina-inicial',
    loadChildren: () =>
      import('./pages/pagina-inicial/pagina-inicial.module').then(
        (m) => m.PaginaInicialModule
      ),
  },
  {
    path: '',
    redirectTo: 'pagina-inicial',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'cadastro-anuncio',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./pages/cadastro-anuncio/cadastro-anuncio.module').then(
        (m) => m.CadastroAnuncioModule
      ),
  },
  {
    path: 'terapeuta',
    loadChildren: () =>
      import('./pages/terapeuta/terapeuta.module').then(
        (m) => m.TerapeutaModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
