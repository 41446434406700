<p-menubar class="menu-desktop" [model]="items" *ngIf="!isMobile">
  <ng-template pTemplate="start">
    <div class="txt-logo text-xl font-bold">
      Massagem<span class="bold">Aqui</span>
    </div>
  </ng-template>

  <ng-template pTemplate="item" let-item>
    <a
      pRipple
      class="flex align-items-center p-menuitem-link"
      (click)="handleMenuItemClick(item, $event)"
    >
      <i [class]="item.icon"></i>
      <span class="ml-2">{{ item.label }}</span>
    </a>
  </ng-template>

  <ng-template pTemplate="end">
    <ng-container *ngFor="let item of itemsEnd">
      <a
        pRipple
        class="inline-flex align-items-center p-menuitem-link mr-3"
        (click)="handleMenuItemClick(item, $event)"
      >
        <i [class]="item.icon"></i>
        <span class="ml-2">{{ item.label }}</span>
      </a>
    </ng-container>
  </ng-template>
</p-menubar>

<div
  class="menu-mobile flex justify-content-between p-3 shadow-2 align-items-center"
  *ngIf="isMobile"
>
  <div class="txt-logo text-xl font-bold">
    Massagem<span class="bold">Aqui</span>
  </div>
  <button
    pButton
    class="p-button-text p-button-plain no-border"
    (click)="menuVisivel = true"
  >
    <i class="pi pi-bars text-xl"></i>
  </button>
</div>

<p-sidebar [(visible)]="menuVisivel" position="right" styleClass="w-18rem">
  <h3 class="mb-3 text-xl font-semibold text-gray-800">Menu</h3>
  <ul class="list-none p-0 m-0">
    <ng-container *ngFor="let item of items">
      <li
        class="py-3 px-3 border-bottom-1 border-200 hover:bg-gray-100 cursor-pointer flex align-items-center gap-2"
        (click)="handleMenuItemClick(item, $event)"
      >
        <i [class]="item.icon + ' text-lg text-gray-600'"></i>
        <span>{{ item.label }}</span>
      </li>
    </ng-container>

    <ng-container *ngFor="let item of itemsEnd">
      <li
        class="py-3 px-3 border-bottom-1 border-200 hover:bg-gray-100 cursor-pointer flex align-items-center gap-2"
        (click)="handleMenuItemClick(item, $event)"
      >
        <i [class]="item.icon + ' text-lg text-gray-600'"></i>
        <span>{{ item.label }}</span>
      </li>
    </ng-container>
  </ul>
</p-sidebar>
